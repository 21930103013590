const { arrayOf, string, bool, shape, number } = require('prop-types');
const agentSpec = require('./agent.spec');
const imageSpec = require('./image.spec');
const phoneSpec = require('./phone.spec');
const stateSpec = require('./state.spec');

module.exports = {
  active: bool,
  isRemote: bool,
  queryType: string,
  queryValue: string,
  alias: string,
  aliasPath: string,
  distanceAway: number,
  address: shape({
    street: string.isRequired,
    additionalStreetInfo: string,
    city: string.isRequired,
    zip: string.isRequired,
    state: shape(stateSpec).isRequired,
  }),
  hours: string,
  dailyHours: shape({
    monday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    tuesday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    wednesday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    thursday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    friday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    saturday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    sunday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    })
  }),
  phones: shape(phoneSpec),
  location: shape({
    coordinates: arrayOf(number.isRequired).isRequired,
  }),
  officeID: string,
  image: shape(imageSpec),
  name: string,
  officeCode: string,
  officeType: string,
  description: string,
  landingPage: string,
  googlePlaceId: string,
  firstPartyReviewPage: string,
  videoUrl: string,
  reservationUrl: string,
  quote: string,
  reviews: arrayOf(
    shape({
      id: number,
      isHidden: bool,
      status: string,
      flagStatus: string,
      url: string,
      rating: number,
      content: string,
      authorName: string,
      publisherDate: number,
      reviewLanguage: string,
      comments: arrayOf(shape({
        id: number,
        publisherDate: number,
        authorName: string,
        authorRole: string,
        authorEmail: string,
        content: string,
        visibility: string,
      }))
    }),
  ),
  rating: string,
  reviewCount: number,
  agents: arrayOf(shape(agentSpec)),
  social: shape({
    facebook: string,
    instagram: string,
    linkedin: string,
  }),
  manager: shape({
    name: string,
    description: string,
    image: shape(imageSpec),
  }),
  listingActive: bool,
  jobPostings: arrayOf(shape({
    title: string,
    listing: string,
    link: string,
  }))
};
