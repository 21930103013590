const { arrayOf, oneOfType, string, bool, shape, number, object } = require('prop-types');
const associateSpec = require('./associate.spec');
const imageSpec = require('./image.spec');
const officeSpec = require('./office.spec');
const phoneSpec = require('./phone.spec');
const stateSpec = require('./state.spec');

module.exports = {
  active: bool,
  isRemote: bool,
  queryType: string,
  queryValue: string,
  alias: string,
  aliasPath: string,
  webId: string,
  rid: string,
  email: string,
  repNum: string,
  type: string,
  nNumber: string,
  firstName: string,
  lastName: string,
  preferredName: string,
  displayName: string,
  bio: string,
  qualifications: arrayOf(shape({ title: string })),
  firstPartyReviewPage: string,
  googlePlaceId: string,
  title: string,
  quote: string,
  videoUrl: string,
  yextTitle: string,
  reservationUrl: string,
  profile: shape({ completeness: number, fields: shape({ image: bool }) }),
  services: arrayOf(string),
  products: arrayOf(string),
  education: arrayOf(shape({
    school: string,
    year: number,
    degree: string,
  })),
  social: shape({
    facebook: string,
    instagram: string,
    linkedin: string,
  }),
  image: shape(imageSpec),
  primaryPhone: shape({
    number: string.isRequired,
    extension: string,
  }),
  phones: oneOfType([arrayOf(shape(phoneSpec)), object]),
  licenseData: shape({
    ak: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    al: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ar: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    az: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ca: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    co: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ct: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    dc: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    de: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    fl: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ga: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    hi: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ia: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    id: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    il: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    in: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ks: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ky: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    la: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ma: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    md: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    me: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    mi: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    mn: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    mo: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ms: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    mt: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    nc: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    nd: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ne: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    nh: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    nj: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    nm: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    nv: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ny: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    oh: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ok: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    or: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    pa: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    pr: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ri: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    sc: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    sd: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    tn: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    tx: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    ut: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    va: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    vt: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    wa: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    wi: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    wv: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
    wy: shape({
      stateName: string,
      licenses: arrayOf(shape({
        type: string,
        number: string,
      })),
    }),
  }),
  languages: arrayOf(
    shape({
      language: string.isRequired,
    }),
  ),
  awards: arrayOf(
    shape({
      title: string.isRequired,
      description: string.isRequired,
    }),
  ),
  associates: arrayOf(shape(associateSpec)),
  tags: shape({
    inclusions: arrayOf(string.isRequired),
    exclusions: arrayOf(string.isRequired),
  }),
  offices: arrayOf(shape(officeSpec)),
  localOffice: shape({
    street: string.isRequired,
    additionalStreetInfo: string,
    city: string.isRequired,
    zip: string.isRequired,
    state: shape(stateSpec).isRequired,
  }),
  localOfficeLocation: shape({
    coordinates: arrayOf(number.isRequired),
  }),
  dailyHours: shape({
    monday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    tuesday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    wednesday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    thursday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    friday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    saturday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    }),
    sunday: shape({
      isClosed: bool,
      byAppointmentOnly: bool,
      eveningsByAppointment: bool,
      start: string,
      end: string
    })
  }),
  landingPage: string,
  distanceAway: number,
  rating: number,
  reviewCount: number,
  reviews: arrayOf(
    shape({
      id: number,
      isHidden: bool,
      status: string,
      flagStatus: string,
      url: string,
      rating: number,
      content: string,
      authorName: string,
      publisherDate: number,
      reviewLanguage: string,
      comments: arrayOf(shape({
        id: number,
        publisherDate: number,
        authorName: string,
        authorRole: string,
        authorEmail: string,
        content: string,
        visibility: string,
      }))
    }),
  ),
};
